import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Col,
  Container,
  ProgressBar,
  Row,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import GanttSchedule from "../components/GanttSchedule/GanttSchedule";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { getProjectData, fetchProjectResourcesTraditional } from "../redux";
import Loader from "../components/Loader";
import ErrorMsg from "../components/ErrorMsg";
import ActivitiesByResourcesTraditional from "../components/TraditionalSchedule/ActivitiesByResourcesTraditional";
import { calcularDuracionV2, desencry, getData } from "../helpers";
import NoAccess from "./NoAccess";
import { selectTooltip } from "../utils/selectTooltip";
import GanttDx from "../components/GanttDx/GanttDx";
import GanttDxClass from "../components/GanttDx/GanttDxClass";
import { useTranslation } from "react-i18next";

const ScheduleTraditional = () => {
  const [t, i18n] = useTranslation("schedule");
  // const [recalc, setRecalc] = useState(0);

  const access = window.localStorage.getItem("accessToSchedule");
  const desData = desencry(access, "accessCloudProjectDevSchedule");

  const [showResources, setShowResources] = useState(false);
  const { uid } = useParams();
  const projectData = useSelector((state) => state.traditional);
  const dispatch = useDispatch();
  const handleShowResources = () => setShowResources(true);
  const handleCloseResources = () => setShowResources(false);
  const [getDirectory, setGetDirectory] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  const [costProject, setCostProject] = useState([]);
  const [ durationAllProjectGantt , setDurationAllProjectGantt] = useState("-")
  const [durationAllProjectLBGantt,setDurationAllProjectLBGantt] = useState("-")

  const getAllDirectory = () => {
    Promise.all([
      getData(`traditional-directory/${uid}`, {}),
      getData(`excluded-dates`, {}),
    ]).then((values) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setGetDirectory(values[0].files);
        setExcludeDates(values[1]);
      });
    });
  };
  const getCostProject = () => {
    Promise.all([getData(`hv-project-header/${uid}`, {})]).then((values) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setCostProject(values[0]);
      });
    });
  };
  useEffect(() => {
    if (uid) {
      dispatch(getProjectData(uid));
      dispatch(fetchProjectResourcesTraditional(uid));
      getAllDirectory();
      getCostProject();
    }
  }, []);

  const updatePctProje = () => {
    dispatch(getProjectData(uid));
    getCostProject();
  };
  let pctCompletado = projectData.projectData.PctCompletadoProyecto;
  let pctPlaneado = projectData.projectData.PctPlaneadoProyecto;
  let valorDesviacion = Math.round(pctCompletado - pctPlaneado);

  let durationAllProject = () => {
    let startDate = projectData.projectData.FechaInicioProg;
    let endDate = projectData.projectData.FechaFinProg;
    return calcularDuracionV2(startDate, endDate, excludeDates);
  };

  let durationAllProjectLb = () => {
    let startDate = projectData.projectData.FechaInicioLB    ;
    let endDate = projectData.projectData.FechaFinLB;
    return calcularDuracionV2(startDate, endDate, excludeDates);
  };

  const costoPlan = `$ ${new Intl.NumberFormat().format(
    costProject.CostoPlaneado || 0
  )}`;
  const costoReal = `$ ${new Intl.NumberFormat().format(
    costProject.CostoReal || 0
  )}`;

  const rol = window.localStorage.getItem("rol");

  const desvIndicadorCalcu = (dur_proyec, dur_lb) =>{
    if( projectData.projectData.FechaInicioLB){
      if(dur_proyec != "--" && dur_lb != "--"){
        const dev = dur_proyec - dur_lb
      return dev
      }
  }else{ 
    console.log("no hay fecha lb")
    return 0
  }
  }

  const datesProje = {
    start_date_proje : projectData.projectData.FechaInicioProg,
    end_date_proje : projectData.projectData.FechaFinProg,
    setduration: setDurationAllProjectGantt
  }
  const datesProjeLB = {
    start_date_lb_proje : projectData.projectData.FechaInicioLB,
    end_date_lb_proje : projectData.projectData.FechaFinLB,
    setduration_lb: setDurationAllProjectLBGantt
  }

  const fcDataBurn = {
    delayIndicator : projectData.projectData.IndicadorRetrasoFc,
    durationFc : projectData.projectData.DuracionProyectadaFc
  }
  
  return (
    <>
      {desData === "true" ? (
        <React.Fragment>
          <Layout>
            <TitleCommon
              title={{
                text: t("schedule_tittle"),
                icon: "chalkboard-teacher",
              }}
              color="secondary"
              /*  button={{
            text: t("schedule_back"),
            icon: "arrow-left",
            url: "/projects",
          }} */
              goBack={{
                text: t("schedule_back"),
                icon: "arrow-left",
              }}
            />
            {projectData.loading ? (
              <Loader />
            ) : projectData.error ? (
              <ErrorMsg title="¡Ups!" msg={t("schedule_alert_1")} />
            ) : (
              <React.Fragment>
                <Container fluid className="bg-light">
                  <Row>
                    <Col className="pt-3 pb-2">
                      <div className="d-flex align-items-center btns-gantt">
                        <h5 className="mb-0 mr-3">
                          <span className="text-primary">
                            {t("schedule_nameproject")}{" "}
                          </span>
                          <span>{projectData.projectData.Nombre}</span>
                        </h5>
                        <div className="group-btns-gantt">
                          <div className="btn-schedule1">
                            <Button
                              className="mr-2 btn-gantt-info"
                              variant="outline-primary"
                              size="sm"
                              as={Link}
                              to={`/hv-project/${uid}`}
                            >
                              <i className="fa fa-tachometer-alt mr-2"></i>
                              {t("schedule_button_report")}
                            </Button>
                            <Button
                              className="btn-gantt-reca mr-2"
                              variant="outline-dark"
                              size="sm"
                              onClick={() => {
                                // setRecalc(recalc + 1)
                                window.location.reload();
                              }}
                            >
                              <i className="fa fa-sync mr-2"></i>
                              {t("schedule_button_recalculate")}
                            </Button>
                          </div>
                          <div className="btn-schedule1">
                            {rol == "Miembro de Equipo" ? (
                              <></>
                            ) : (
                              <Button
                                variant="outline-dark"
                                size="sm"
                                onClick={handleShowResources}
                                className="mr-2 btn-gantt-resour"
                              >
                                <i className="fa fa-users mr-2"></i>
                                {t("schedule_button_resources")}
                              </Button>
                            )}

                            <Button
                              className="mr-2 btn-gantt-gest"
                              variant="outline-dark"
                              size="sm"
                              as={Link}
                              to={`/files-project/${uid}`}
                            >
                              {getDirectory.length > 0 ? (
                                <>
                                  {" "}
                                  <i className="fa fa-folder-open mr-1"></i> (
                                  <span className="text-danger">
                                    {projectData.projectData.CantidadArchivos}
                                  </span>
                                  ){" "}
                                </>
                              ) : (
                                <i className="fa fa-folder mr-2"></i>
                              )}
                              {t("schedule_button_documentation")}
                            </Button>
                            {rol == "Miembro de Equipo" ? (
                              <>
                                <Button
                                  className="mr-2 btn-gantt-gest"
                                  variant="outline-dark"
                                  size="sm"
                                  as={Link}
                                  to={"/tasks"}
                                  /* to={`/files-project/${uid}`} */
                                >
                                  {t("schedule_button_progress")}
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      className="mb-2 mb-md-0 d-flex flex-column justify-content-around "
                    >
                      <div className="d-flex align-items-center">
                        <small style={{ width: "110px" }}>
                          <b className="text-dark">Plan.</b> 
                          ({projectData.projectData.PctPlaneadoProyectoLB || 0} %)
                          {/* { uid !== "7EAE00F6-6402-4D72-9492-150BD4596C9F" ? (projectData.projectData.PctPlaneadoProyectoLB || 0) : "100 "} % */}
                        </small>
                        <ProgressBar
                          style={{ height: 8, flex: 1 }}
                          className="mb-0"
                          variant="info"
                          now={projectData.projectData.PctPlaneadoProyectoLB || 0}
                         /*  now = {uid !== "7EAE00F6-6402-4D72-9492-150BD4596C9F" ? (projectData.projectData.PctPlaneadoProyectoLB || 0): 100} */
                          // label={
                          //   <small>{`Plan. ${
                          //     projectData.projectData.PctPlaneadoProyecto || 0
                          //   }%`}</small>
                          // }
                          key={2}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        <small style={{ width: "110px" }}>
                          <b className="text-dark">Comp.</b> (
                          {projectData.projectData.PctCompletadoProyecto || 0}%)
                        </small>
                        <ProgressBar
                          style={{ height: 8, flex: 1 }}
                          className="mb-0"
                          variant="success"
                          now={
                            projectData.projectData.PctCompletadoProyecto || 0
                          }
                          // label={
                          //   <small>{`Comp. ${
                          //     projectData.projectData.PctCompletadoProyecto || 0
                          //   }%`}</small>
                          // }
                          key={1}
                        />
                      </div>
                    </Col>
                    <Col className="pl-0">
                      <div className="d-flex flex-wrap justify-content-between mb-2 border py-1 rounded">
                        <small className="px-1 py-1">
                          {valorDesviacion >= 0 && (
                            <>
                              <i
                                className="fa fa-check-circle text-success mr-2 my-auto"
                                title={t("schedule_indicator_onTime")}
                              ></i>
                              {t("schedule_indicator")}:{" "}
                              {t("schedule_indicator_onTime")}
                            </>
                          )}
                          {valorDesviacion <= -1 && valorDesviacion >= -25 && (
                            <>
                              <i
                                className="fa fa-exclamation-circle text-warning  mr-2 my-auto"
                                title={t("schedule_indicator_moderate")}
                              ></i>
                              {t("schedule_indicator")}:{" "}
                              {t("schedule_indicator_moderate")}
                            </>
                          )}
                          {valorDesviacion <= -26 && (
                            <>
                              <i
                                className="fa fa-exclamation-circle text-danger  mr-2 my-auto"
                                title={t("schedule_indicator_critical")}
                              ></i>
                              {t("schedule_indicator")}:{" "}
                              {t("schedule_indicator_critical")}
                            </>
                          )}
                        </small>
                        <small className="px-1 py-1">
                          <i className="fa fa-signal mr-2 text-secondary"></i>
                          {t("schedule_indicator_desv")}:{" "}
                          { uid !== "7EAE00F6-6402-4D72-9492-150BD4596C9F" ? 
                         (desvIndicadorCalcu(durationAllProjectGantt,durationAllProjectLBGantt) || 0 ) : "0 "  } días 
                        {/*   { uid !== "7EAE00F6-6402-4D72-9492-150BD4596C9F" ?  
                             (desvIndicadorCalcu(durationAllProjectGantt,durationAllProjectLBGantt) || 0 ) : (fcDataBurn.delayIndicator? fcDataBurn.delayIndicator : 0 )  } días */}
                        </small>
                        <small className="px-1 py-1 ">
                          <i className="fa fa-user mr-2 text-secondary"></i>
                          {t("schedule_manager")}:{" "}
                          {projectData.projectData.Gerente || "-"}
                        </small>
                        <small className="px-1 py-1">
                          <i className="fa fa-money-bill-wave mr-2 text-info"></i>
                          <b>Plan.: {costoPlan || "-"}</b>
                        </small>
                        <small className="px-1 py-1">
                          <i className="fa fa-money-bill-wave mr-2 text-success"></i>
                          <b>Comp.: {costoReal || "-"}</b>
                        </small>
                       
                      </div>
                      <div className="d-flex flex-wrap justify-content-between mb-2 border py-1 rounded">
                        <small className="px-1 py-1 pl-2">
                          <i className="fa fa-play mr-2 text-secondary"></i>
                          {t("schedule_start")}:{" "}
                          {projectData.projectData.FechaInicioReal ||
                            projectData.projectData.FechaInicioProg ||
                            "-"}
                        </small>
                        <small className="px-1 py-1">
                          <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
                          {t("schedule_end")}:{" "}
                          {projectData.projectData.FechaFinProg || "-"}
                        </small>
                        {/* btn new */}
                        <small className="px-1 py-1">
                          <i className="fa fa-hourglass-end mr-2 text-secondary"></i>
                          {t("schedule_duration")}:{" "}
                        {/*  {durationAllProjectGantt || "-"} días */}
                         {/*  {durationAllProject() || "-"} d  */}
                         { uid !== "7EAE00F6-6402-4D72-9492-150BD4596C9F" ? 
                            (durationAllProjectGantt || "-") : "148 "  } días 
                             {/* (durationAllProjectGantt || "-") : (fcDataBurn.durationFc? fcDataBurn.durationFc : 0 )} días */}
                        </small> 
                        <small className="px-1 py-1 ">
                          <i className="fa fa-clock mr-2 text-secondary"></i>
                          {t("schedule_modified")}:{" "}
                          {projectData.projectData.UltActualizacion || "-"}
                        </small>                       
                        
                        {/* btn new */}
                      </div>
                      <div  className="d-flex flex-wrap justify-content-between mb-2 border py-1 rounded">
                        <small className="px-1 py-1 pl-2">
                          <i className="fa fa-play mr-2 text-secondary"></i>
                          {t("schedule_start_lb")}:{" "}
                          {projectData.projectData.FechaInicioLB || "-"}
                        </small>
                        <small className="px-1 py-1">
                          <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
                          {t("schedule_end_lb")}:{" "}
                          {projectData.projectData.FechaFinLB || "-"}
                        </small>
                        <small className="px-1 py-1">
                          <i className="fa fa-hourglass-end mr-2 text-secondary"></i>
                          {t("schedule_duration_lb")}:{" "}
                          {/* {durationAllProjectLb() || "-"} d */}
                          {/* {durationAllProjectLBGantt || "-"} días */}
                          { uid !== "7EAE00F6-6402-4D72-9492-150BD4596C9F" ?  
                             (durationAllProjectLBGantt || "-") : "148 "  } días
                        </small>
                        <small className="px-1 py-1">
                          <i class="fa fa-grip-lines  mr-2 text-secondary"></i>
                          {t("schedule_baseline")}
                          { projectData.projectData.CantidadLB || "-"} 
                        </small>
                      </div>
                      <Modal
                        show={showResources}
                        onHide={handleCloseResources}
                        contentClassName="border-0 shadow-lg"
                        centered
                        size="xl"
                      >
                        <Modal.Header >
                          <Modal.Title className="h5">
                            {t("schedule_modal_resources_tittle")}
                          </Modal.Title>
                          <Button onClick={handleCloseResources} variant="light">X</Button>
                        </Modal.Header>
                        <Modal.Body>
                          <ActivitiesByResourcesTraditional />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="dark" onClick={handleCloseResources}>
                            {t("schedule_modal_resources_button_close")}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Row>
                </Container>
                <Container fluid>
                  <div className="mt-3 mb-5">
                    {/*  <GanttSchedule uid={uid} updatePctProje={updatePctProje} /> */}
                    {/* <GanttDx uid={uid} updatePctProje={updatePctProje} /> */}
                    <GanttDxClass updatePctProje={updatePctProje} uid={uid} projectName= {projectData.projectData.Nombre} excludeDates={excludeDates} dates = {datesProje} dateslb ={datesProjeLB} />
                  </div>
                </Container>
              </React.Fragment>
            )}
          </Layout>
        </React.Fragment>
      ) : (
        <>
          <NoAccess />
        </>
      )}
    </>
  );
};

export default ScheduleTraditional;
